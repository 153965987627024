<template>
  <div class="issue-summary">
    <div class="issue-summary--section">
      <p>Status</p>
      <issue-status
        @issue-resolved="$emit('issue-resolved', $event)"
        :status.sync="issueObj.status"
      />
    </div>
    <div class="issue-summary--section">
      <p>Complaint Originator</p>
      <div class="issue-personnel">
        <eden-avatar :size="24" />
        <router-link
          v-if="issueObj?.affected_customer_type === 'customer'"
          class="text-primary"
          :to="{
            name: 'customers.individual',
            params: { id: issueAffectedUser?.id },
          }"
        >
          {{ issueAffectedUser?.name }}</router-link
        >
        <span v-else>{{ issueAffectedUser?.name }}</span>
      </div>
    </div>
    <div class="issue-summary--section" v-if="issueObj?.gardener">
      <p>Gardener</p>
      <div class="issue-personnel">
        <eden-avatar :size="24" />
        <span>{{ issueObj.gardener.name }}</span>
      </div>
    </div>
    <div class="issue-summary--section">
      <p>
        Other Affected
        {{
          issueObj?.affected_customer_type === "customer"
            ? "Customers"
            : "Humaans"
        }}
        ({{ issueOtherAffectedUsers?.length || 0 }})
      </p>
      <div
        v-for="(customer, index) in issueOtherAffectedUsers"
        :key="index"
        class="issue-personnel"
      >
        <eden-avatar :size="24" />
        <router-link
          v-if="issueObj?.affected_customer_type === 'customer'"
          class="text-primary"
          :to="{
            name: 'customers.individual',
            params: { id: customer.id },
          }"
        >
          {{ customer?.name }}</router-link
        >
        <span v-else>{{ customer?.name }}</span>
      </div>
    </div>
    <div class="issue-summary--section">
      <p>Department</p>
      <span>{{ issueObj.department }}</span>
    </div>
    <div
      class="issue-summary--section"
      v-if="issueObj?.supporting_departments?.length > 0"
    >
      <p>Supporting Departments</p>
      <div
        v-for="(departments, index) in issueObj.supporting_departments"
        :key="index"
        class="issue-personnel"
      >
        <span>{{ departments }}</span>
      </div>
    </div>
    <div class="issue-summary--section" v-if="issueObj?.riders_id?.length > 0">
      <p>Riders</p>
      <div
        v-for="(rider, index) in issueObj?.riders_id"
        :key="index"
        class="issue-personnel"
      >
        <eden-avatar :size="24" />
        <span>{{ rider.firstname + " " + rider.lastname }}</span>
        <span> - {{ rider.company?.name }}</span>
      </div>
    </div>
    <div class="issue-summary--section">
      <p>Resolvers</p>
      <div
        v-for="(resolver, index) in issueObj?.resolvers"
        :key="index"
        class="issue-personnel"
      >
        <eden-avatar :size="24" />
        <span>{{ resolver?.name }}</span>
      </div>
    </div>
    <div class="issue-summary--section">
      <p>Nudge count</p>
      <p>
        {{
          issueObj.nudges_sent +
          " time" +
          `${issueObj?.nudges_sent > 1 ? "s" : ""}`
        }}
      </p>
    </div>
    <template v-if="issueObj?.order_id">
      <p class="text-bold my-3">Order Details</p>
      <div class="issue-summary--section">
        <p>Order Type</p>
        <span>{{
          formatName(
            issueObj?.order_type?.replace("-", " ") || "Subscription",
          )?.replace("-", " ")
        }}</span>
      </div>
      <div class="issue-summary--section">
        <p>Order ID</p>
        <router-link
          class="text-primary"
          :to="{
            name: 'orders.order',
            params: { id: issueObj?.order_id },
          }"
        >
          {{ issueObj?.order_id }}</router-link
        >
      </div>
      <div class="issue-summary--section">
        <p>Item</p>
        <span>{{ issueObj?.order_specifics }}</span>
      </div>
      <div class="issue-summary--section">
        <p>Service Partner</p>
        <span>{{ issueObj?.service_provider_id?.name }}</span>
      </div>
      <div class="issue-summary--section">
        <p>Service date</p>
        <span>{{ formatDate(issueObj?.order_service_date, "do m, y") }}</span>
      </div>
    </template>
    <div class="issue-summary--section">
      <p>Issue created by {{ issueObj?.reporter?.name }}</p>
      <span class="font-sm"
        >On
        {{
          formatDateAndTime(issueObj?.created_at, "m do, y") +
          " (" +
          formatTimeFromNow(issueObj.created_at, true) +
          ")"
        }}</span
      >
    </div>

    <!-- <div class="issue-summary--section" v-if="!issueObj?.resolved_time">
      <p>Time difference between when this issue was logged and now:</p>
      <span>{{ diff_hours(issueObj?.created_at, new Date()) }} ago</span>
    </div>

    <div class="issue-summary--section" v-if="issueObj?.resolved_time">
      <p>
        Time difference between when this issue was logged and when it was solved:
      </p>
      <span
        >{{ diff_hours(issueObj.created_at, issueObj.resolved_time) }} ago</span
      >
    </div> -->
  </div>
</template>

<script>
import IssueStatus from "@/components/Feedback/Issues/Issue/Structure/IssueStatus";
export default {
  name: "IssueSummary",
  components: { IssueStatus },
  props: {
    issue: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      issueObj: {},
    };
  },
  computed: {
    issueId() {
      return this.$route.params.id;
    },
    issueAffectedUser() {
      return this.issueObj.affected_user;
    },
    issueOtherAffectedUsers() {
      return this.issueObj.other_affected_users;
    },
  },
  watch: {
    issue() {
      this.issueObj = this.issue.filter(
        (issue) => issue.id === parseInt(this.issueId),
      )[0];
    },
  },
  methods: {
    diff_hours(dt2, dt1) {
      console.log(dt2, dt1);
      var diff = (new Date(dt1).getTime() - new Date(dt2).getTime()) / 1000;
      diff /= 60 * 60;

      if (diff < 1) {
        let result = Math.round(diff * 60);
        return result + " munite" + `${result > 1 ? "s" : ""}`;
      } else {
        let result = Math.round(diff);
        console.log(result);
        return result + " hour" + `${result > 1 ? "s" : ""}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.issue-summary--section {
  margin-bottom: 30px;

  > p:first-child {
    color: var(--eden-grey-tertiary);
    margin-bottom: 8px;
    font-size: 0.875rem;
  }

  span {
    font-size: 0.875rem;
  }

  .issue-personnel {
    display: flex;
    align-items: center;

    a,
    span {
      font-size: 0.875rem;
      margin-left: 10px;
      display: inline-block;
    }

    + .issue-personnel {
      margin-top: 15px;
    }

    &:only-of-type {
      margin-bottom: 8px;
    }
  }
}
</style>
