<template>
  <div>
    <el-dropdown @command="command">
      <el-button type="plain">
        Actions <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <template v-for="(action, i) in actions">
          <el-dropdown-item
            v-if="allowAccessFor(action.access)"
            :icon="action.icon"
            :key="i"
            :command="{
              command: action.command,
              component: action.component,
            }"
          >
            {{ action.label }}</el-dropdown-item
          >
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <component
      :is="action.component"
      :show.sync="action.visibility"
      :issue="issue"
      :action="action.command"
      @success="$emit('action')"
    />
  </div>
</template>

<script>
export default {
  name: "IssueActions",
  props: {
    issue: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    IssueForm: () => import("../../IssueForm"),
    IssueDelete: () => import("./IssueDelete"),
    IssueNotification: () => import("./IssueNotification"),
  },
  data() {
    return {
      action: {
        command: "",
        component: "",
        visibility: false,
      },
      actions: [
        {
          access: [
            "admin",
            "gardener",
            "gardener_pro",
            "growth",
            "kitchen",
            "operations",
            "superadmin",
          ],
          icon: "eden-icon-edit",
          label: "Edit issue",
          command: "edit",
          component: "issue-form",
        },
        {
          access: ["admin", "operations"],
          icon: "eden-icon-delete",
          label: "Delete issue",
          command: "delete",
          component: "issue-delete",
        },
        {
          access: ["admin", "operations"],
          icon: "el-icon-chat-square",
          label: "Nudge resolvers",
          command: "nudge",
          component: "issue-notification",
        },
      ],
    };
  },
  methods: {
    command(command) {
      this.action.command = command.command;
      this.action.component = command.component;
      setTimeout(() => {
        this.action.visibility = true;
      }, 100);
    },
  },
};
</script>
