import axios from "axios";

export default {
  index(page) {
    return axios.get(`feedback/issues?page=${page}`);
  },

  filter(payload) {
    return axios.post("clusters/all/filter", payload);
  },

  add(payload) {
    return axios.post("feedback/issues", payload);
  },

  update(payload) {
    return axios.post("crm/issue-tracker/update", payload);
  },

  updateStatus(payload) {
    return axios.post("crm/issue-tracker/update-status", payload);
  },

  resolve(payload) {
    return axios.post("crm/issue-tracker/resolve", payload);
  },

  delete(payload) {
    return axios.delete("crm/issue-tracker", { data: payload });
  },

  notify(payload) {
    return axios.post("crm/issue-tracker/nudge-resolvers", payload);
  },

  comment(payload) {
    return axios.post("crm/issue-tracker/comment", payload);
  },

  updateComment(payload) {
    return axios.post("crm/issue-tracker/comment/update", payload);
  },

  deleteComment(payload) {
    return axios.delete("crm/issue-tracker/comment", { data: payload });
  },

  issuesTracker(params = "") {
    return axios.get(`crm/issue-tracker${params}`);
  },

  addIssues(payload) {
    return axios.post("crm/issue-tracker", payload);
  },

  export(params) {
    return axios.get(`crm/issue-tracker/all?${params}`);
  },

  summary(start_date, end_date) {
    return axios.get(
      `crm/summary/issue-tracker?start_date=${start_date}&end_date=${end_date}`,
    );
  },
};
