var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"issue-summary"},[_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v("Status")]),_c('issue-status',{attrs:{"status":_vm.issueObj.status},on:{"issue-resolved":function($event){return _vm.$emit('issue-resolved', $event)},"update:status":function($event){return _vm.$set(_vm.issueObj, "status", $event)}}})],1),_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v("Complaint Originator")]),_c('div',{staticClass:"issue-personnel"},[_c('eden-avatar',{attrs:{"size":24}}),(_vm.issueObj?.affected_customer_type === 'customer')?_c('router-link',{staticClass:"text-primary",attrs:{"to":{
          name: 'customers.individual',
          params: { id: _vm.issueAffectedUser?.id },
        }}},[_vm._v(" "+_vm._s(_vm.issueAffectedUser?.name))]):_c('span',[_vm._v(_vm._s(_vm.issueAffectedUser?.name))])],1)]),(_vm.issueObj?.gardener)?_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v("Gardener")]),_c('div',{staticClass:"issue-personnel"},[_c('eden-avatar',{attrs:{"size":24}}),_c('span',[_vm._v(_vm._s(_vm.issueObj.gardener.name))])],1)]):_vm._e(),_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v(" Other Affected "+_vm._s(_vm.issueObj?.affected_customer_type === "customer" ? "Customers" : "Humaans")+" ("+_vm._s(_vm.issueOtherAffectedUsers?.length || 0)+") ")]),_vm._l((_vm.issueOtherAffectedUsers),function(customer,index){return _c('div',{key:index,staticClass:"issue-personnel"},[_c('eden-avatar',{attrs:{"size":24}}),(_vm.issueObj?.affected_customer_type === 'customer')?_c('router-link',{staticClass:"text-primary",attrs:{"to":{
          name: 'customers.individual',
          params: { id: customer.id },
        }}},[_vm._v(" "+_vm._s(customer?.name))]):_c('span',[_vm._v(_vm._s(customer?.name))])],1)})],2),_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v("Department")]),_c('span',[_vm._v(_vm._s(_vm.issueObj.department))])]),(_vm.issueObj?.supporting_departments?.length > 0)?_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v("Supporting Departments")]),_vm._l((_vm.issueObj.supporting_departments),function(departments,index){return _c('div',{key:index,staticClass:"issue-personnel"},[_c('span',[_vm._v(_vm._s(departments))])])})],2):_vm._e(),(_vm.issueObj?.riders_id?.length > 0)?_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v("Riders")]),_vm._l((_vm.issueObj?.riders_id),function(rider,index){return _c('div',{key:index,staticClass:"issue-personnel"},[_c('eden-avatar',{attrs:{"size":24}}),_c('span',[_vm._v(_vm._s(rider.firstname + " " + rider.lastname))]),_c('span',[_vm._v(" - "+_vm._s(rider.company?.name))])],1)})],2):_vm._e(),_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v("Resolvers")]),_vm._l((_vm.issueObj?.resolvers),function(resolver,index){return _c('div',{key:index,staticClass:"issue-personnel"},[_c('eden-avatar',{attrs:{"size":24}}),_c('span',[_vm._v(_vm._s(resolver?.name))])],1)})],2),_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v("Nudge count")]),_c('p',[_vm._v(" "+_vm._s(_vm.issueObj.nudges_sent + " time" + `${_vm.issueObj?.nudges_sent > 1 ? "s" : ""}`)+" ")])]),(_vm.issueObj?.order_id)?[_c('p',{staticClass:"text-bold my-3"},[_vm._v("Order Details")]),_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v("Order Type")]),_c('span',[_vm._v(_vm._s(_vm.formatName( _vm.issueObj?.order_type?.replace("-", " ") || "Subscription", )?.replace("-", " ")))])]),_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v("Order ID")]),_c('router-link',{staticClass:"text-primary",attrs:{"to":{
          name: 'orders.order',
          params: { id: _vm.issueObj?.order_id },
        }}},[_vm._v(" "+_vm._s(_vm.issueObj?.order_id))])],1),_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v("Item")]),_c('span',[_vm._v(_vm._s(_vm.issueObj?.order_specifics))])]),_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v("Service Partner")]),_c('span',[_vm._v(_vm._s(_vm.issueObj?.service_provider_id?.name))])]),_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v("Service date")]),_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.issueObj?.order_service_date, "do m, y")))])])]:_vm._e(),_c('div',{staticClass:"issue-summary--section"},[_c('p',[_vm._v("Issue created by "+_vm._s(_vm.issueObj?.reporter?.name))]),_c('span',{staticClass:"font-sm"},[_vm._v("On "+_vm._s(_vm.formatDateAndTime(_vm.issueObj?.created_at, "m do, y") + " (" + _vm.formatTimeFromNow(_vm.issueObj.created_at, true) + ")"))])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }