<template>
  <div>
    <el-select
      v-model="setStatus"
      placeholder="Status"
      :class="['issue-status', setStatus]"
      @change="changeStatus"
    >
      <el-option
        v-for="(status, index) in statuses"
        :key="index"
        :label="status.label"
        :value="status.value"
      >
        <span
          ><span :class="['issue-status--indicator', status.value]"></span
          >{{ status.label }}</span
        >
      </el-option>
    </el-select>
    <issue-resolve
      :show="show"
      @issue-resolved="$emit('issue-resolved', $event)"
      :status="setStatus"
      @close="closeResolve"
      :selected-issues="selectedIssues"
      :is-bulk="isBulk"
    />
  </div>
</template>
<script>
import IssueResolve from "@/components/Feedback/Issues/Issue/Structure/IssueResolve";
import issues from "@/requests/feedback/issues";
export default {
  name: "IssueStatus",
  components: { IssueResolve },
  props: {
    status: {
      type: String,
      default: "",
      required: false,
    },
    selectedIssues: {
      type: Array,
      default: () => [],
      required: false,
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      statuses: [
        {
          value: "not-started",
          label: "Not Started",
        },
        {
          value: "ongoing",
          label: "Ongoing",
        },
        {
          value: "blocked",
          label: "Blocked",
        },
        {
          value: "resolved",
          label: "Resolved",
        },
      ],
    };
  },
  computed: {
    issueId() {
      return this.$route.params.id;
    },
    setStatus: {
      get() {
        return this.status;
      },
      set(value) {
        this.$emit("update:status", value);
      },
    },
  },
  mounted() {},
  methods: {
    changeStatus(item) {
      if (this.selectedIssues.length !== 0) {
        if (item === "resolved") {
          this.show = true;
        } else {
          this.$emit("selected-issues-updated", item);
        }
        return;
      }
      if (item === "resolved") {
        this.show = true;
      } else {
        const { logged_in_userId } = this.$store.getters.user;
        const payload = {
          resolved_by: logged_in_userId,
          issue_id: this.issueId,
          status: item,
        };
        issues
          .update(payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.sending = false;
            }
          })
          .catch((error) => {
            this.$message.error(error.response?.data.message);
            this.sending = false;
          });
      }
    },
    closeResolve() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
.issue-status--indicator {
  display: inline-block;
  position: relative;
  top: -2px;
  margin-right: 8px;
  height: 5px;
  width: 5px;
  border-radius: 100px;
  background: var(--eden-grey-quinary);

  // .el-input__inner {
  //   border: none;
  // }

  &.not-started {
    background: var(--eden-grey-quinary);
  }
  &.ongoing {
    background: var(--eden-orange-quaternary);
  }
  &.blocked {
    background: var(--eden-red-quaternary);
  }
  &.resolved {
    background: var(--eden-green-quaternary);
  }
}

.issue-status {
  width: 160px;

  // .el-input__inner {
  //   border: none;
  // }

  .el-input__suffix-inner {
    i {
      color: var(--eden-grey-primary) !important;
      font-weight: 600;
    }
  }

  &.not-started {
    .el-input__inner {
      background-color: var(--eden-grey-septenary);
      transition: background-color 0.25s ease-in;
    }
  }

  &.ongoing {
    .el-input__inner {
      background-color: var(--eden-orange-senary);
      transition: background-color 0.25s ease-in;
    }
  }

  &.blocked {
    .el-input__inner {
      background-color: var(--eden-red-senary);
      transition: background-color 0.25s ease-in;
    }
  }

  &.resolved {
    .el-input__inner {
      background-color: var(--eden-green-senary);
      transition: background-color 0.25s ease-in;
    }
  }
}
</style>
