<template>
  <eden-confirm-dialog
    title="Resolve this issue?"
    :button-type="'success'"
    :button-text="'Resolve Issue'"
    :button-status="sending"
    :disabled="disabledButton"
    :show.sync="setShow"
    @confirm="resolveIssue"
    @close="closeEvent"
  >
    <p>How was this issue resolved?</p>
    <el-input
      v-model="resolution"
      id="textarea"
      type="textarea"
      placeholder="Type your resolution here..."
    />
  </eden-confirm-dialog>
</template>

<script>
import issues from "@/requests/feedback/issues";

export default {
  name: "IssueResolve",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    issue: {
      type: Array,
      default() {
        return [];
      },
    },
    status: {
      type: String,
      default: "",
      required: false,
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
    selectedIssues: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      deleting: false,
      issueObj: {},
      resolution: "",
      sending: false,
    };
  },
  computed: {
    issueId() {
      return this.$route.params.id;
    },
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    disabledButton() {
      return this.resolution === "" ? true : false;
    },
  },
  mounted() {
    this.issueObj = this.issue.filter(
      (issue) => issue.id === parseInt(this.issueId),
    )[0];
  },
  methods: {
    closeEvent() {
      this.$emit("close");
    },
    resolveIssue() {
      this.sending = true;
      const { logged_in_userId } = this.$store.getters.user;
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        today.getDate().toString().padStart(2, "0");
      var time =
        today.getHours().toString().padStart(2, "0") +
        ":" +
        today.getMinutes().toString().padStart(2, "0") +
        ":" +
        today.getSeconds().toString().padStart(2, "0");
      const dateTime = date + " " + time;

      if (this.isBulk) {
        const issueId = this.selectedIssues;

        const payload = {
          issue_ids: issueId,
          resolution: this.resolution,
          resolved_by: logged_in_userId,
          resolved_time: dateTime,
          status: "resolved",
        };

        issues
          .updateStatus(payload)
          .then((response) => {
            console.log(response, "resolved");
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.closeEvent();
              this.$emit("issue-resolved", response.data.data);
              this.resolution = "";
            }
          })
          .catch((error) => {
            this.$message.error(error.response?.data.message);
          })
          .finally(() => {
            this.sending = false;
          });
        return;
      }

      const payload = {
        resolution: this.resolution,
        resolved_by: logged_in_userId,
        issue_id: this.issueId,
        status: this.status,
        resolved_time: dateTime,
      };
      issues
        .update(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.sending = false;
            this.closeEvent();
            this.$emit("issue-resolved", response.data.data);
            this.resolution = "";
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.sending = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
