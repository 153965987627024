<template>
  <div>
    <eden-page-header :title="'Issues Tracker'" :subtitle="`${issueTitle}`">
      <template slot="actions">
        <issue-actions
          :issue="issue"
          @action="getIssue"
          v-if="
            allowAccessFor([
              'superadmin',
              'admin',
              'operations',
              'kitchen',
              'growth',
              'gardener',
              'gardener_pro',
            ])
          "
        />
      </template>
    </eden-page-header>
    <eden-loader v-if="loading" />
    <div class="issue">
      <div class="issue-body">
        <div class="issue-body--header">
          <div>
            <h5>{{ issueTitle }}</h5>
            <div>
              <eden-services-list
                v-if="issueObj.issue_service"
                :type="'single'"
                :service="issueObj.issue_service"
              />
              <el-tag
                type="info"
                v-for="(category, idx) in issueObj.categories"
                :key="idx"
                >{{ formatToTitleCase(category.name) }}</el-tag
              >
            </div>
          </div>
          <div class="is-flex">
            <el-tag v-if="issueObj.priority" :type="setType(issue.priority)"
              >{{ formatText(issueObj.priority) }} Priority</el-tag
            >
            <el-tag v-if="issueObj.priority" :type="setType(issue.priority)">{{
              formatText(
                issueObj.order_type?.replace("-", " ") || "Subscription",
              ).replace("-", " ")
            }}</el-tag>
          </div>
        </div>
        <div class="issue-body--description">
          <h6>Description</h6>
          <p>{{ issueObj.description }}</p>
        </div>
        <div class="issue-body--attachments">
          <h6>Attachments</h6>
          <eden-attachments
            :attachments="issueObj.attachments"
            :action="'download'"
          />
        </div>

        <div class="issue-body--resolutions" v-if="issueObj.resolution">
          <h6>Resolution</h6>
          <p>{{ issueObj.resolution }}</p>
          <p class="resolution">
            Resolved by {{ issueObj.resolved_by.name }} at
            {{ formatDate(issueObj.resolved_time, "m do, y") }} ;
            {{ formatTime(issueObj.resolved_time, "12h") }}
            ( Resolution time:
            {{
              formtTimeDifference(issueObj.created_at, issueObj.resolved_time)
            }})
          </p>
        </div>
        <div class="issue-body--comments">
          <h6>Comments</h6>
          <issue-comments
            :issue-status="issueObj.status"
            :comments="issueObj.comments"
            @_getIssue="getIssue"
          />
        </div>
      </div>
      <issue-summary @issue-resolved="getIssue" :issue="issue" />
    </div>
  </div>
</template>

<script>
import IssueActions from "@/components/Feedback/Issues/Issue/Actions/IssueActions";
import IssueComments from "@/components/Feedback/Issues/Issue/Structure/IssueComments";
import IssueSummary from "@/components/Feedback/Issues/Issue/Structure/IssueSummary";

// import { formatIssues } from "@/components/Feedback/Issues/issues.config";

import issues from "@/requests/feedback/issues";

export default {
  name: "Issue",
  components: { IssueSummary, IssueComments, IssueActions },
  data() {
    return {
      loading: false,
      issue: [],
      issueObj: {},
    };
  },
  computed: {
    issueId() {
      return this.$route.params.id;
    },
    issueTitle() {
      return this.issueObj && this.formatToTitleCase(this.issueObj.title);
    },
    issueOtherAffectedUsers() {
      return this.issueObj.other_affected_users;
    },
  },
  created() {
    this.getIssue();
  },
  mounted() {
    console.log(this.issue, this.issueObj);
    this.resolvingIssue();
  },
  methods: {
    getIssue() {
      this.loading = true;
      issues
        .issuesTracker(`?issue_id=${this.issueId}`)
        .then((response) => {
          this.loading = false;
          const { status, data } = response.data;

          if (status && data.length) {
            this.$emit("logged");
            this.issue = data;
            this.issueData = this.issue.filter(
              (issue) => issue.id === Number(this.issueId),
            );
            this.issueObj = {
              ...this.issueData[0],
              comments: this.issueData[0]?.comments?.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at),
              ),
            };
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
    resolvingIssue() {
      if (this.issueObj.status === "resolved") {
        console.log("Test");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$-summary-width: 250px;

.issue {
  position: relative;
  width: 100%;
  display: flex;

  &-body {
    width: calc(100% - #{$-summary-width});

    > div {
      &:not(:last-child) {
        margin-bottom: 40px;
      }

      h6 {
        font-size: 1rem;
        font-family: "CerebriSans", sans-serif;
        margin-bottom: 15px;
      }
    }

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        font-size: 1.5rem;
        font-family: "CerebriSans", sans-serif;
        margin-bottom: 10px;
      }
    }

    &--description {
      p {
        font-size: 0.875rem;
        line-height: 1.8;
      }
    }

    &--resolutions {
      border-top: 1px solid var(--eden-grey-septenary);
      padding-top: 40px;
      p {
        font-size: 0.875rem;
        line-height: 1.8;
        &.resolution {
          color: var(--eden-grey-tertiary);
          margin-top: 8px;
        }
      }
    }

    &--comments {
      border-top: 1px solid var(--eden-grey-septenary);
      padding-top: 40px;
    }
  }

  &-summary {
    width: $-summary-width;
    border-left: 1px solid var(--eden-grey-septenary);
    padding-left: 40px;
    margin-left: 40px;
  }
}
</style>
